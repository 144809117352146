import {Injectable} from '@angular/core';
import {AngularFirestore, AngularFirestoreCollection, DocumentReference} from "@angular/fire/compat/firestore";
import {combineLatest, filter, from, Observable} from "rxjs";
import {Task,} from "./models/task.model";
import {Project} from "./models/project.model";
import {Message} from "./interfaces/comments.interface";
import firebase from "firebase/compat/app";
import {TaskEmployeeModel} from "./models/taskemployee.model";
import {catchError, finalize, map} from "rxjs/operators";
import {FileUploadTask} from "./task-file-upload/file-upload-task.model";
import {AngularFireStorage, AngularFireUploadTask} from "@angular/fire/compat/storage";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import UploadTaskSnapshot = firebase.storage.UploadTaskSnapshot;
import User = firebase.User;

@Injectable({
  providedIn: 'root'
})
export class TasksService {
  private tasksCollection: AngularFirestoreCollection<Task>;
  private projectCollection: AngularFirestoreCollection<Project>
  basePath = 'tasksDocuments'
  currentUser: User | null;
  userName = '';

  constructor(private firestore: AngularFirestore,
              private fb: FormBuilder,
              private storage: AngularFireStorage) {
    this.tasksCollection = this.firestore.collection<Task>('tasks');
    this.projectCollection = this.firestore.collection<Project>('projects');
    const userData = JSON.parse(localStorage.getItem('userData')!);
    this.userName = userData?.userName;
    this.currentUser = JSON.parse(localStorage.getItem('user')!);
  }

  getTasks(): Observable<Task[]> {

    return this.tasksCollection.valueChanges({idField: 'id'});
  }

  getProjects(): Observable<Project[]> {
    return this.projectCollection.valueChanges({idField: 'id'});
  }

  updateCollaborators(data: TaskEmployeeModel[], taskId: string) {
    const collaboratorsField = firebase.firestore.FieldValue.arrayUnion(...data);

    return this.firestore.collection('tasks').doc(taskId).update({
      collaborators: data
    })
  }


  addTask(task: Task) {
    return this.tasksCollection.add(task).then(res => {
      this.tasksCollection.doc(res.id).update({id: res.id})
    });
  }

  addProject(project: Project): Promise<DocumentReference<Project>> {
    return this.projectCollection.add(project);
  }

  updateProject(project: Project): void {
    const projectDoc = this.projectCollection.doc(project.id);
    projectDoc.update(project);
  }

  async updateTask(task: Task) {
    const taskDoc = this.tasksCollection.doc(task.id);
    return await taskDoc.update(task);
  }

  deleteTask(task: Task) {
    const taskDoc = this.tasksCollection.doc(task.id);
    return taskDoc.delete()
  }

  deleteProject(project: Project): void {
    const projectDoc = this.projectCollection.doc(project.id);
    projectDoc.delete().then(r => {

    });
  }

  getCommentsFromTask(id: string) {
    return this.firestore.collection('tasks').doc(id).collection<Message>('comments').valueChanges({idField: 'id'});
  }

  addComment(id: string, comment: Message) {
    return this.firestore.collection('tasks').doc(id).collection<Message>('comments').add(comment)
  }

  getColaboratorsFromTask(taskId: string) {
    return this.firestore.collection<Task>('tasks').doc(taskId).valueChanges();

  }


  fetchTasksByProjectId(projectId: string): Observable<Task[]> {
    if (projectId === "All" || projectId === "My Tasks") {
      return this.firestore.collection<Task>('tasks').valueChanges();
    }

    // No need to create a separate query variable
    return from(
      this.firestore.collection<Task>('tasks', (ref) =>
        ref.where('project.id', '==', projectId)
      ).valueChanges()
    ) as Observable<Task[]>;
  }

  // getMyTasks(userName: string): Observable<Task[]> {
  //   return this.firestore.collection<Task>('tasks', (ref) =>
  //     ref.where('taskFor', 'array-contains', userName)
  //       .where('taskByName', '==', userName)
  //   ).valueChanges();
  // }
  getMyTasks(userName: string): Observable<Task[]> {
    const tasksByTaskFor$ = this.firestore.collection<Task>('tasks', (ref) =>
      ref.where('taskFor', 'array-contains', userName)
    ).valueChanges();

    const tasksByTaskByName$ = this.firestore.collection<Task>('tasks', (ref) =>
      ref.where('taskByName', '==', userName)
    ).valueChanges();

    return combineLatest([tasksByTaskFor$, tasksByTaskByName$]).pipe(
      map(([tasksByTaskFor, tasksByTaskByName]) => {
        // Combine the results and remove duplicates
        const allTasks = [...tasksByTaskFor, ...tasksByTaskByName];
        return this.removeDuplicateTasks(allTasks);
      })
    );
  }

// Helper function to remove duplicate tasks based on their ID
  removeDuplicateTasks(tasks: Task[]): Task[] {
    const uniqueTasks = new Map<string, Task>();
    tasks.forEach(task => uniqueTasks.set(task.id!, task));
    return Array.from(uniqueTasks.values());
  }

  pushFileToStorage(fileUpload: FileUploadTask, taskId: string): Observable<UploadTaskSnapshot | undefined> {
    const filePath = `${this.basePath}/${fileUpload.file.name}`;
    const storageRef = this.storage.ref(filePath);
    const uploadTask = this.storage.upload(filePath, fileUpload.file);

    uploadTask.snapshotChanges().pipe(
      finalize(() => {
        storageRef.getDownloadURL().subscribe({
          next: (downloadURL: string) => {
            fileUpload.url = downloadURL;
            fileUpload.name = fileUpload.file.name;
            this.saveFileData(fileUpload, taskId);
          },
          error: (error) => {
            console.error("Error getting download URL:", error);
            // Handle the error, e.g., display an error message to the user
          }
        });
      }),
      catchError(error => {
        console.error("Error uploading file:", error);
        // Handle the error, e.g., display an error message to the user
        throw error; // Re-throw the error to propagate it to the subscriber
      })
    )
      .subscribe();
    return uploadTask.snapshotChanges()
  }

  private saveFileData(fileUpload: FileUploadTask, taskId: string) {
    const attachment = {
      fileName: fileUpload.name,
      uploadedAt: new Date(),
      uploadedBy: fileUpload.uploadedBy,
      status: 'uploaded',
      uploadedById: fileUpload.uploadedById, // Use currentUser's ID
      // fileUrl: new FormControl(file.webkitRelativePath),
      url: fileUpload.url,
    }
    this.firestore.doc(`tasks/${taskId}`).set({
      attachments: firebase.firestore.FieldValue.arrayUnion(attachment)
    }, {merge: true}).then(_ => {
      console.log(`Added Attachment in ${taskId}`)
    }).catch(err => {
        console.log(`Error adding attachment in ${taskId} ERROR :: ${err}`)
      }
    )


  }

   createTaskForm(data?: Task): FormGroup {
// alert(this.currentUser?.uid)
    return this.fb.group({
      id: [data?.id || this.getRandomID()],
      img: [data?.img || 'assets/user1.png'],
      projectName: [data?.projectName || null],
      projectId: [data?.projectId || null],
      taskByName: [this.currentUser?.email || '', Validators.required],
      taskById: [this.currentUser?.uid || '', Validators.required],
      taskFor: [data?.taskFor || null],
      title: [data?.title || null, Validators.required],
      done: [data?.done || null],
      project: [null],
      subTasks: this.fb.array([]),
      attachments: this.fb.array([]),
      priority: [data?.priority || null],
      due_date: [data?.due_date?.toDate() || null],
      note: [data?.note, Validators.required],
    });
// if(this.isNewEvent)this.taskForm.get('taskById')?.setValue(this.currentUser?.uid || '')
//     if(this.isNewEvent)this.taskForm.get('taskByName')?.setValue(this.currentUser?.email || '')
  }

  private getRandomID(): number {
    const S4 = () => {
      return ((1 + Math.random()) * 0x10000) | 0;
    };
    return S4() + S4();
  }

  addAutomatedTask(data:  Partial<Task>) {
  let mTaskForm=  this.createTaskForm(data as Task);
return   this.addTask(mTaskForm.value)

  }

}

