<div>
  <h1  class="rounded text-center mt-2"> Education MIS Report</h1>
  <div class="d-flex gap-1">
    <mat-form-field appearance="outline">
      <mat-label>Enter a date range</mat-label>
      <mat-date-range-input [rangePicker]="picker">
        <input matStartDate [(ngModel)]="startDate" placeholder="Start date" />
        <input matEndDate [(ngModel)]="endDate" placeholder="End date" />
      </mat-date-range-input>
      <mat-hint>DD/MM/YYYY – DD/MM/YYYY</mat-hint>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>
    </mat-form-field>

<!--    <mat-form-field appearance="outline">-->
<!--      <mat-label>Select Centre</mat-label>-->
<!--      <mat-select [(ngModel)]="selectedCentre" (selectionChange)="OnCentreChange($event)">-->
<!--        <mat-option *ngFor="let c of mCentres" [value]="c.centreCode">{{ c.centreName }}</mat-option>-->
<!--      </mat-select>-->
<!--    </mat-form-field>-->

    <button mat-mini-fab color="accent" (click)="Search()">
      <mat-icon>search</mat-icon>
    </button>
    <button mat-mini-fab color="accent" (click)="Clear()">
      <mat-icon>clear</mat-icon>
    </button>
  </div>
  <mat-progress-bar *ngIf="isLoading" mode="indeterminate">Please wait Data is loading... </mat-progress-bar>
  <table>
    <thead>
    <tr>
      <th>Non-Formal Education Intervention</th>
      <th *ngFor="let centre of mCentres">{{ centre.centreName }}</th>
      <th>Total</th>
    </tr>
    </thead>
    <tbody>
    <!-- Loop through session data rows with fallback -->
<!--    <ng-container *ngFor="let session of sessionData">-->
<!--      <tr>-->
<!--        <td>{{ session.type }}</td>-->

<!--        &lt;!&ndash; Check if session.counts has data &ndash;&gt;-->
<!--        <ng-container *ngIf="session.counts && session.counts.length > 0; else noSessionData">-->
<!--          <td *ngFor="let count of session.counts">{{ count || 0 }}</td>-->
<!--        </ng-container>-->

<!--        &lt;!&ndash; Fallback row when no data is available in session.counts &ndash;&gt;-->
<!--        <ng-template #noSessionData>-->
<!--          <td *ngFor="let centre of mCentres">0</td>-->
<!--        </ng-template>-->

<!--        &lt;!&ndash; Total column &ndash;&gt;-->
<!--        <td class="total-column">{{ session.total || 0 }}</td>-->
<!--      </tr>-->
<!--    </ng-container>-->


<!--Rapport Building Session (A) -->
    <tr>
      <td>Rapport Building Session (A)</td>

      <ng-container *ngIf="allRapportBuildingSessionCountTotal  > 0; else noCentreDataG">
        <td *ngFor="let centreData of allRapportBuildingSessionCount">{{ centreData.sessionCountBySession || 0 }}</td>
      </ng-container>

      <ng-template #noCentreDataG>
        <td *ngFor="let centre of mCentres">0</td>
      </ng-template>

      <td class="total-column">{{ allRapportBuildingSessionCountTotal || 0 }}</td>
    </tr>
<!--    Baseline Assessment (B)-->
    <tr>
      <td>Baseline Assessment (B)</td>

      <ng-container *ngIf="allBaselineAssessmentSessionCountTotal  > 0; else noCentreDataG">
        <td *ngFor="let centreData of allBaselineAssessmentSessionCount">{{ centreData.sessionCountBySession || 0 }}</td>
      </ng-container>

      <ng-template #noCentreDataG>
        <td *ngFor="let centre of mCentres">0</td>
      </ng-template>

      <td class="total-column">{{ allBaselineAssessmentSessionCountTotal || 0 }}</td>
    </tr>
    <!-- Individual Sessions by AL Teacher (C) with fallback -->
    <tr>
      <td>Individual Sessions by AL Teacher (C)</td>
      <!-- Check if allCentreSessions has data -->
      <ng-container *ngIf="allIndividualSessionsByALTeacherTotal > 0; else noCentreData">
        <td *ngFor="let centreData of allIndividualSessionsByALTeacherCount">{{ centreData.sessionCountBySession  || 0 }}</td>
      </ng-container>

      <!-- Fallback row when no data is available in allCentreSessions -->
      <ng-template #noCentreData>
        <td *ngFor="let centre of mCentres">0</td>
      </ng-template>

      <td class="total-column">{{ allIndividualSessionsByALTeacherTotal || 0 }}</td>
    </tr>
    <!-- Individual Sessions done by Volunteers (D) TSS with fallback -->
    <tr>
      <td>Individual Sessions done by Volunteers (D) TSS</td>

      <!-- Check if totalTssVolunteerCount is greater than 0, otherwise fallback to 'noCentreDataD' template -->
      <ng-container *ngIf="allIndividualSessionsdoneVolunteersTotal > 0; else noCentreDataD">
        <!-- Loop through the center data and display childrenCount for each center -->
        <td *ngFor="let centreData of allIndividualSessionsdoneVolunteersCount">
          {{ centreData.childrenCount || 0 }}
        </td>
      </ng-container>

      <!-- Fallback template when no data for this center (0 sessions) -->
      <ng-template #noCentreDataD>
        <td *ngFor="let centre of mCentres">0</td>
      </ng-template>

      <!-- Display total count at the end -->
      <td class="total-column">{{ allIndividualSessionsdoneVolunteersTotal || 0 }}</td>
    </tr>
    <!--    Intermediate Test Conducted (E)-->
    <tr>
      <td>Intermediate Test Conducted (E)</td>
      <ng-container *ngIf="allIntermediateAssessmentSessionCountTotal  > 0; else noCentreDataG">
        <td *ngFor="let centreData of allIntermediateAssessmentSessionCount">{{ centreData.sessionCountBySession || 0 }}</td>
      </ng-container>

      <ng-template #noCentreDataG>
        <td *ngFor="let centre of mCentres">0</td>
      </ng-template>

      <td class="total-column">{{ allIntermediateAssessmentSessionCountTotal || 0 }}</td>
    </tr>
    <!--    Discharge Test (F)-->
    <tr>
      <td>Discharge Test (F)</td>

      <ng-container *ngIf="allDischargeTestSessionCountTotal > 0; else noCentreDataF">
        <td *ngFor="let centreData of allDischargeTestSessionCount">{{ centreData.sessionCountBySession || 0 }}</td>
      </ng-container>

      <ng-template #noCentreDataF>
        <td *ngFor="let centre of mCentres">0</td>
      </ng-template>

      <td class="total-column">{{ allDischargeTestSessionCountTotal || 0 }}</td>
    </tr>
    <!-- Group sessions (G) with fallback -->
    <tr>
      <td>Group sessions (G)</td>
      <ng-container *ngIf="allGroupSessionsTotal > 0; else noCentreDataG">
        <td *ngFor="let centreData of allGroupSessionsCount">{{ centreData.sessionCountBySession || 0 }}</td>
      </ng-container>

      <ng-template #noCentreDataG>
        <td *ngFor="let centre of mCentres">0</td>
      </ng-template>

      <td class="total-column">{{ allGroupSessionsTotal || 0 }}</td>
    </tr>
    <!-- Total sessions (A+B+C+D+E+F+G) with fallback -->
    <tr>
      <td>Total sessions (A+B+C+D+E+F+G)</td>

      <!-- Check if there is any valid session data -->
      <ng-container *ngIf="calculateCenterWiseTotalSessionSum().length > 0; else noCombinedTotals">

        <!-- Loop through center-wise session totals -->
        <td *ngFor="let total of calculateCenterWiseTotalSessionSum()">{{ total || 0 }}</td>
      </ng-container>

      <!-- Template for fallback if no combined totals -->
      <ng-template #noCombinedTotals>
        <td *ngFor="let centre of mCentres">0</td>
      </ng-template>

      <!-- Display the overall grand total session count (or 0 if not available) -->
      <td class="total-column">{{ calculateGrandTotal() || 0 }}</td>
    </tr>

    <!-- Child's average session done with fallback -->
    <tr>
      <td>Child's average session done</td>
      <ng-container *ngIf="childAverages && childAverages.length > 0; else noAverageData">
        <td *ngFor="let centreAverage of childAverages">
          <!-- Custom rounding logic -->
          {{ customRound(centreAverage)||0 }}
        </td>
      </ng-container>
      <ng-template #noAverageData>
        <td *ngFor="let centre of mCentres">0</td>
      </ng-template>

      <td class="total-column">{{ (totalAverage || 0).toFixed(0) }}</td>
    </tr>


    </tbody>




  </table>
</div>
