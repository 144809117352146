import {Component, OnInit} from '@angular/core';
import {AngularFirestore} from "@angular/fire/compat/firestore";
import {FullChild} from "../interfaces/FullChild";


import * as XLSX from 'xlsx';
import {uniq} from "lodash";
// import {hospital} from "@igniteui/material-icons-extended";
import {Centre} from "../interfaces/Centres";
import {AdmissionLogService} from "../services/admission-log.service";
import {Timestamp} from "firebase/firestore";

@Component({
    selector: 'app-code',
    templateUrl: './code.component.html',
    styleUrls: ['./code.component.scss']
})
export class CodeComponent implements OnInit {
    mData: any[] = []
    tableData: any[] = []

    constructor(private readonly mFirestore: AngularFirestore,
                private readonly mLogService: AdmissionLogService,) {
// mLogService.CreateAdmissionLogs();
        mLogService.CreateAdmissionLogs()
//         this.mLogService.deleteAllAdmissionLogs()
    }

    ngOnInit(): void {
        const startDate = new Date(2023, 9, 1); // Months are zero-based, so 9 represents October
        const endDate = new Date(2023, 11, 31); // Months are zero-based, so 12 represents December

//         this.mFirestore.collection<FullChild>('ALChild')
//             .valueChanges({idField: 'id'})
//             .subscribe(res => {
//                 // alert(res.length)
//                 let childbyadmission: FullChild[] = []
//                 res.forEach(it => {
//
//                     it.admissionData.forEach((c, i) => {
//                         if (i % 2 == 0) {  //ADMISSION
//                             if (c.dateOfAdmission &&  (c.dateOfAdmission as unknown as Timestamp)!.toDate()!! >= startDate!! && (c.dateOfAdmission! as unknown as Timestamp).toDate()!! <= endDate) childbyadmission.push(it)
//                         }
//                     })
//                 })
//
//                 childbyadmission.forEach(child => {
//                     let type = child?.admissionData.length > 2 ? 'Follow Up' : 'Unique'
//                     let ageGroup = this.getAgeGroup(child.age)
//                     let obj = {
//                         hospital: child.hospital,
//                         centre: child.centreName,
//                         gender: child.gender,
//                         ageGroup: ageGroup,
//                         type: type,
//                         'childCode': child.childCode
//                     }
//                     this.mData.push(obj)
//
//                 })
//                 console.log(this.mData)
//
// // Get unique hospitals  //STEP1
//                 const uniqueHospitals = [...new Set(this.mData.map(child => child.hospital))];
//                 console.log(uniqueHospitals);
//
//                 // Get unique centres
//                 const uniqueCentres = [...new Set(this.mData.map(child => child.centre))];
//                 console.log(uniqueCentres);
//              let   data2:any[]=[]
//
//
//               uniqueHospitals.forEach(h=>{
//            uniqueCentres.forEach(c=>{
//              if(this.mData.filter(c1=>c1.hospital==h && c1.centre == c ).length>=1)  {
//                    let obj = {
//
//                            hospital: h,
//                            centre: c,
//                            count: this.mData.filter((child) => child.hospital == h && child.centre == c).length
//
//                    }
//                    data2.push(obj)
//
//              }
//            })
//
//               })
//                 console.log(data2)
//                 // this.tableData = data2
//                 // console.log(this.tableData)
//
// // Reformatting the data
//                 this.tableData =data2.reduce((acc, curr) => {
//                     const hospital = acc.find((item:any) => item.hospital === curr.hospital);
//                     if (hospital) {
//                         hospital.centres.push({ centre: curr.centre, count: curr.count });
//                     } else {
//                         acc.push({
//                             hospital: curr.hospital,
//                             centres: [{ centre: curr.centre, count: curr.count }]
//                         });
//                     }
//                     return acc;
//                 }, []);
//                 console.log(this.tableData)
//             })
//

    }

    // Function to organize the data by hospital and centre
    organizeDataByHospitalAndCentre(): any {
        const hospitalCentreData: any = {};
        this.mData.forEach(child => {
            const {hospital, centre, gender, ageGroup, type, childCode} = child;
            const key = `${hospital}_${centre}`;
            if (!hospitalCentreData[key]) {
                hospitalCentreData[key] = [];
            }
            hospitalCentreData[key].push({hospital, centre, gender, ageGroup, type, childCode});
        });
        return hospitalCentreData;
    }

    private getAgeGroup(age: number): string {
        let ageGroup = ''
        if (age != null) {
            if (age <= 3 && age > 0) {
                ageGroup = '0-3'
            } else if (age >= 4 && age <= 7) {
                ageGroup = '4-7'
            } else if (age >= 8 && age <= 10) {
                ageGroup = '8-10'
            } else if (age >= 11 && age <= 14) {
                ageGroup = '11-14'
            } else ageGroup = 'Others'

        }
        return ageGroup
    }

    private download(mData: any[]) {
        const excelData = this.mData.map(child => {
            return {
                Hospital: child.hospital,
                Centre: child.centre,
                AgeGroup: child.ageGroup,
                Type: child.type,
                Gender: child.gender
                // Add more fields as needed
            };
        });

// Create a new workbook and add the worksheet
        const worksheet = XLSX.utils.json_to_sheet(excelData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Data');

// Convert the workbook to a binary Excel file and trigger download
        const excelBuffer = XLSX.write(workbook, {bookType: 'xlsx', type: 'array'});
        const blob = new Blob([excelBuffer], {type: 'application/octet-stream'});
        const fileName = 'mData.xlsx';

// Trigger download

        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

    }

    getTotalRows(hospitalData: any): number {
        let totalRows = 0;
        for (const centre in hospitalData) {
            totalRows += hospitalData[centre].length;
        }
        return totalRows;
    }

    // Function to get the unique hospitals from the data
    getUniqueHospitals(): string[] {
        return Object.keys(this.tableData);
    }
}
