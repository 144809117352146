import {RouteInfo} from './sidebar.metadata';


export const ROUTES: RouteInfo[] = [
  {
    path: '',
    title: 'operations',
    icon: '',
    class: 'header',
    groupTitle: true,
    submenu: [],
    showMenu: false
  },
  {
    path: 'dashboard',
    title: 'Dashboard',
    icon: 'pie-chart',
    class: '',
    groupTitle: false,
    submenu: [],
    showMenu: false
  },
  {
    path: 'home',
    title: 'Admissions',
    icon: 'file-text',
    class: 'menu-toggle',
    groupTitle: false,
    showMenu: false,
    submenu: [
      // {
      //   path: 'dashboard',
      //   title: 'Dashboard',
      //   icon: 'sidebar',
      //   class: 'ml-menu',
      //   groupTitle: false,
      //   submenu: []
      // },
      // {
      //     path: 'childdetails',
      //     title: 'Child Admission',
      //     icon: 'smile',
      //     class: 'ml-menu',
      //     groupTitle: false,
      //     submenu: [],
      //     showMenu: false,
      // },
      {
        path: 'child-list',
        title: 'Child List',
        icon: 'sidebar',
        class: 'ml-menu',
        groupTitle: false,
        submenu: [],
        showMenu: false,
      },
      {
        path: 'prescreening',
        title: 'Prescreening List',
        icon: 'sidebar',
        class: 'ml-menu',
        groupTitle: false,
        showMenu: false,
        submenu: []
      },
      {
        path: 'Units',
        title: 'Units',
        icon: 'sidebar',
        class: 'ml-menu',


        groupTitle: false,
        showMenu: false,
        submenu: []
      },


    ]
  },
  {
    path: 'counseling',
    title: 'Counselling',
    icon: 'mic',
    class: 'menu-toggle',
    groupTitle: false,
    showMenu: true,
    submenu: [
      {
        path: 'counseling/individual',
        title: 'Individual Sessions',
        icon: 'sidebar',
        class: '',
        groupTitle: false,
        showMenu: false,
        submenu: []
      },
      {
        path: 'counseling/group',
        title: 'Group Sessions',
        icon: 'sidebar',
        class: '',
        groupTitle: false,
        showMenu: false,
        submenu: []
      },
      {
        path: 'counseling/assessments',
        title: 'Assessments',
        icon: 'sidebar',
        class: '',
        groupTitle: false,
        showMenu: true,
        submenu: []
      },
      {
        path: '',
        title: 'Follow Up',
        icon: 'sidebar',
        class: 'menu-toggle',

        groupTitle: false,
        showMenu: true,
        submenu: [
          {
            path: 'counseling/follow-up-table',
            title: 'Follow Up Assessment',
            icon: 'sidebar',
            class: '',
            groupTitle: false,
            showMenu: true,
            submenu: []
          },
          {
            path: 'questions/counselling-question-list',
            title: 'Follow Up Questions',
            icon: 'sidebar',
            class: '',
            groupTitle: false,
            showMenu: true,
            submenu: []
          },
        ]
      },

      {
        path: 'counseling/reports',
        title: 'Reports',
        icon: 'sidebar',
        class: '',
        groupTitle: false,
        showMenu: false,
        submenu: []
      },
    ]
  },


  {
    path: 'education',
    title: 'Education',
    icon: 'book-open',
    class: 'menu-toggle',
    groupTitle: false,
    showMenu: false,
    submenu: [
      {
        path: 'education/individual',
        title: 'Individual',
        icon: 'sidebar',
        class: '',
        groupTitle: false,
        showMenu: false,
        submenu: []
      },
      {
        path: 'education/group',
        title: 'Group Sessions',
        icon: 'sidebar',
        class: '',
        groupTitle: false,
        showMenu: false,
        submenu: []
      },
      {
        path: 'education/education-report',
        title: 'Reports',
        icon: 'sidebar',
        class: '',
        groupTitle: false,
        showMenu: false,
        submenu: []
      },
      {
        path: 'education/follow-up-table',
        title: 'Followup Assessment',
        icon: 'sidebar',
        class: '',
        groupTitle: false,
        showMenu: true,
        submenu: []
      },
      {
        path: 'questions/education-question-list',
        title: 'Follow Up Questions',
        icon: 'sidebar',
        class: '',
        groupTitle: false,
        showMenu: true,
        submenu: []
      },
    ]
  },
  {
    path: 'inventory',
    title: 'Inventory',
    icon: 'shopping-bag',
    class: 'menu-toggle',
    groupTitle: false,
    showMenu: false,
    submenu: [


      {
        path: 'inventory/products',
        title: 'Products',
        icon: 'sidebar',
        class: 'ml-menu',
        groupTitle: false,
        showMenu: true,
        submenu: []
      },
      {
        path: 'inventory/purchase_product_tbl',
        title: 'Instock Entry',
        icon: 'sidebar',
        class: 'ml-menu',
        groupTitle: false,
        showMenu: true,
        submenu: []
      },


      {
        path: 'inventory/centreInventory',
        title: 'Centre Inventory',
        icon: 'sidebar',
        class: 'ml-menu',
        groupTitle: false,
        showMenu: true,
        submenu: []
      },


      {
        path: 'inventory/stock_adjustment',
        title: 'Stock Adjustment',
        icon: 'sidebar',
        class: 'ml-menu',
        groupTitle: false,
        showMenu: true,
        submenu: []
      },
      // {
      //   path: 'inventory/stock_adjustment_view',
      //   title: 'Stock Adjustment view',
      //   icon: 'sidebar',
      //   class: 'ml-menu',
      //   groupTitle: false,
      //   showMenu: false,
      //   submenu: []
      // },
      {
        path: 'inventory/IncomingOutgoingTransfer',
        title: 'Stock Transfer',
        icon: 'sidebar',
        class: 'ml-menu',
        groupTitle: false,
        showMenu: true,
        submenu: []
      },
      // {
      //       path: 'inventory/inventory_report',
      //       title: 'Inventory Report',
      //       icon: 'sidebar',
      //       class: 'ml-menu',
      //       groupTitle: false,
      //       submenu: []
      //   },
      {
        path: 'inventory/direct-issue-table',
        title: 'Direct Issue',
        icon: 'sidebar',
        class: 'ml-menu',
        groupTitle: false,
        showMenu: true,
        submenu: []
      },
      {
        path: 'inventory/inventory-requests',
        title: 'Request Products',
        icon: 'sidebar',
        class: 'ml-menu',
        groupTitle: false,
        showMenu: true,
        submenu: []
      },




      //{
      //   path: 'inventory/request_product_new',
      //   title: 'Request Product',
      //   icon: 'sidebar',
      //   class: 'ml-menu',
      //   groupTitle: false,
      //   showMenu: true,
      //   submenu: []
      // },


      {
        path: 'inventory/issue_tbl',
        title: 'Issue Requests',
        icon: 'sidebar',
        class: 'ml-menu',
        groupTitle: false,
        showMenu: true,
        submenu: []
      },
      {
        path: 'inventory/Issued_product_list',
        title: 'Issued Products',
        icon: 'sidebar',
        class: 'ml-menu',
        groupTitle: false,
        showMenu: true,
        submenu: []
      },
      {
        path: 'inventory/purchase-list',
        title: 'Purchase List',
        icon: 'sidebar',
        class: 'ml-menu',
        groupTitle: false,
        showMenu: true,
        submenu: []
      },
      {
        path: 'inventory/hygiene-kit',
        title: 'Hygiene Kit',
        icon: 'sidebar',
        class: 'ml-menu',
        showMenu: false,


        groupTitle: false,
        submenu: []
      },
    ]
  },




  // Transport
  {
    path: '#',
    title: 'Transport',
    icon: 'Truck',
    class: 'menu-toggle',
    groupTitle: false,
    showMenu: false,
    submenu: [
      {
        path: 'driver-table',
        title: 'Drivers',
        icon: 'truck',
        class: 'ml-menu',
        groupTitle: false,
        showMenu: true,
        submenu: [


        ]
      },
      {
        path: 'vehicle-table',
        title: 'Vehicles',
        icon: 'Truck',
        class: 'ml-menu',
        groupTitle: false,
        showMenu: true,
        submenu: [


        ]
      },
      {
        path: 'car-log-book',
        title: 'Trip Log',
        icon: 'Truck',
        class: 'ml-menu',
        groupTitle: false,
        showMenu: true,
        submenu: [


        ]
      },
      {
        path: 'fuels-details',
        title: 'Fuel Filling Log',
        icon: 'Truck',
        class: 'ml-menu',
        groupTitle: false,
        showMenu: true,
        submenu: [


        ]
      },
      {
        path: 'servicing-details',
        title: 'Servicing Log',
        icon: 'Truck',
        class: 'ml-menu',
        groupTitle: false,
        showMenu: true,
        submenu: [


        ]
      },
    ]
  },




  {
    path: '',
    title: 'Donation Reports',
    icon: 'trello',
    class: 'menu-toggle',
    groupTitle: false,
    showMenu: false,
    submenu: [
      {
        path: 'donation',
        title: 'Donation Report',
        icon: 'gift',
        class: 'ml-menu',
        groupTitle: false,
        showMenu: false,
        submenu: []
      },
      {
        path: 'attempted-donations',
        title: 'Attempted Donations',
        icon: 'gift',
        class: 'ml-menu',
        groupTitle: false,
        showMenu: false,
        submenu: []
      },{
        path: 'failed-donations',
        title: 'Failed Donations',
        icon: 'gift',
        class: 'ml-menu',
        groupTitle: false,
        showMenu: false,
        submenu: []
      },
      {
        path: 'razorpay-data',
        title: 'Razorpay Payments',
        icon: 'dollar-sign',
        class: 'ml-menu',
        groupTitle: false,
        showMenu: false,
        submenu: []
      },
    ]
  },

  {
    path: '',
    title: 'Counselling Reports',
    icon: 'trello',
    class: 'menu-toggle',
    groupTitle: false,
    showMenu: true,
    submenu: [

      {
        path: 'reports/impact-report-counseling-table',
        title: 'Counseling Impact Report',
        icon: 'trello',
        class: '',
        groupTitle: false,
        showMenu: true,
        submenu: []
      },
      {
        path: 'reports/challenges',
        title: 'Challenges Report',
        icon: 'trello',
        class: '',
        groupTitle: false,
        showMenu: true,
        submenu: []
      },
      {
        path: 'counseling/reports',
        title: 'Progress Report',
        icon: 'trello',
        class: '',
        groupTitle: false,
        showMenu: true,
        submenu: []
      },

      {
        path: 'reports/intervention',
        title: 'Intervention  Report',
        icon: 'trello',
        class: '',
        groupTitle: false,
        showMenu: true,
        submenu: []
      },
      {
        path: 'reports/comparison',
        title: 'Comparison ',
        icon: 'trello',
        class: '',
        groupTitle: false,
        showMenu: true,
        submenu: []
      },
      {
        path: 'reports/counselling-count-report',
        title: 'Session Count ',
        icon: 'trello',
        class: '',
        groupTitle: false,
        showMenu: true,
        submenu: []
      },
      {
        path: 'reports/follow-up-overview2',
        title: 'Followup Overview ',
        icon: 'trello',
        class: '',
        groupTitle: false,
        showMenu: true,
        submenu: []
      },
      // {
      //   path: 'counseling/reports',
      //   title: 'Progress Reports',
      //   icon: 'sidebar',
      //   class: '',
      //   groupTitle: false,
      //   showMenu: true,
      //   submenu: []
      // },
    ]
  },

  {
    path: '',
    title: 'Ops Reports',
    icon: 'trello',
    class: 'menu-toggle',
    groupTitle: false,
    showMenu: false,
    submenu: [

      {
        path: 'reports/child-report',
        title: 'Child Report',
        icon: 'trello',
        class: '',
        groupTitle: false,
        showMenu: true,
        submenu: []
      },
      {
        path: 'reports/admission-report',
        title: 'Admission Report',
        icon: 'trello',
        class: '',
        groupTitle: false,
        showMenu: true,
        submenu: []
      },


      {
        path: 'reports/weekly-report',
        title: 'Weekly Report',
        icon: 'trello',
        class: '',
        groupTitle: false,
        showMenu: true,
        submenu: []
      },
      {
        path: 'quarterly-report',
        title: 'Quarterly Report',
        icon: 'trello',
        class: '',
        groupTitle: false,
        showMenu: false,
        submenu: []
      },
      {
        path: 'reports/units',
        title: 'Unit & Floor Report',
        icon: 'clock',
        class: '',
        groupTitle: false,
        showMenu: true,
        submenu: []
      },
      {
        path: 'reports/age-group',
        title: 'Age Group Report',
        icon: 'trello',
        class: '',
        groupTitle: false,
        showMenu: true,
        submenu: []
      },
      {
        path: 'reports/hospital-count-report',
        title: 'Hospital Count Report',
        icon: 'trello',
        class: '',
        groupTitle: false,
        showMenu: true,
        submenu: []
      },
      {
        path: 'reports/hospital-wise-occupancy',
        title: 'Hospital Wise Occ.',
        icon: 'trello',
        class: '',
        groupTitle: false,
        showMenu: true,
        submenu: []
      },
      {
        path: 'reports/hospitals',
        title: 'Child Wise Hospital',
        icon: 'trello',
        class: '',
        groupTitle: false,
        showMenu: true,
        submenu: []
      },
      {
        path: 'reports/location-state-report',
        title: 'State Count Report',
        icon: 'clock',
        class: '',
        groupTitle: false,
        showMenu: true,
        submenu: []
      },
      {
        path: 'reports/child-case-study',
        title: 'Case Study',
        icon: 'trello',
        class: '',
        groupTitle: false,
        showMenu: true,
        submenu: []
      },
      {
        path: 'reports/treatment-duration',
        title: 'Duration Report',
        icon: 'trello',
        class: '',
        groupTitle: false,
        showMenu: true,
        submenu: []
      },
      {
        path: 'reports/allergy-type-report',
        title: 'Allergy Report',
        icon: 'trello',
        class: '',
        groupTitle: false,
        showMenu: true,
        submenu: []
      },
      {
        path: 'reports/gender-report',
        title: 'Gender Count Report',
        icon: 'trello',
        class: '',
        groupTitle: false,
        showMenu: true,
        submenu: []
      },
      {
        path: 'reports/count-report',
        title: 'Yearly Count Report',
        icon: 'trello',
        class: '',
        groupTitle: false,
        showMenu: true,
        submenu: []
      },
      {
        path: 'reports/blood-group-report',
        title: 'Blood Group Report',
        icon: 'trello',
        class: '',
        groupTitle: false,
        showMenu: true,
        submenu: []
      },


    ]
  },
  {
    path: '',
    title: 'Education Reports',
    icon: 'trello',
    class: 'menu-toggle',
    groupTitle: false,
    showMenu: true,
    submenu: [


      {
        path: 'reports/impact-report-table',
        title: 'Education Impact Report',
        icon: 'trello',
        class: '',
        groupTitle: false,
        showMenu: true,
        submenu: []
      },
      {
        path: 'reports/report-card-table',
        title: 'Report Card',
        icon: 'bar-chart',
        class: '',
        groupTitle: false,
        showMenu: true,
        submenu: []
      },


      {
        path: 'reports/attendance',
        title: '' +
          '' +
          '' +
          '' +
          '' +
          'Analytical Report',
        icon: 'book',
        class: '',
        groupTitle: false,
        showMenu: true,
        submenu: []
      },
      {
        path: 'reports/education-count-report',
        title: 'Session Count ',
        icon: 'trello',
        class: '',
        groupTitle: false,
        showMenu: true,
        submenu: []
      },
      {
        path: 'reports/education-overview',
        title: 'Follow Up Report ',
        icon: 'trello',
        class: '',
        groupTitle: false,
        showMenu: true,
        submenu: []
      },
      {
        path: 'education/education-mis-report',
        title: 'MIS Report',
        icon: 'trello',
        class: '',
        groupTitle: false,
        showMenu: true,
        submenu: []
      },




    ]
  },


  {
    path: '',
    title: 'Donation',
    icon: 'gift',
    class: 'menu-toggle',
    groupTitle: false,
    showMenu: false,
    submenu: [
      {
        path: 'meals',
        title: 'Mealmate',
        icon: 'trello',
        class: '',
        groupTitle: false,
        showMenu: false,
        submenu: []
      },
      {
        path: 'meals/bookings',
        title: 'Bookings',
        icon: 'book',
        class: '',
        groupTitle: false,
        showMenu: true,
        submenu: []
      },
      {
        path: 'unitsponsorship',
        title: 'Unit Sponsorship',
        icon: 'trello',
        class: '',
        groupTitle: false,
        showMenu: false,
        submenu: []
      },


    ]
  },
  {
    path: 'reminders',
    title: 'Reminders',
    icon: 'clock',
    class: 'menu-toggle',
    groupTitle: false,
    showMenu: true,
    submenu: [
      {
        path: '/home/payments',
        title: 'Payments',
        icon: 'clock',
        class: '',
        groupTitle: false,
        showMenu: false,
        submenu: []
      },
      {
        path: '/home/followup-admissions',
        title: 'Followups',
        icon: 'clock',
        class: '',
        groupTitle: false,
        showMenu: false,
        submenu: []
      },
      {
        path: 'reports/birthday-report',
        title: 'Birthdays',
        icon: 'trello',
        class: '',
        groupTitle: false,
        showMenu: false,
        submenu: []
      },


    ]
  },
  {
    path: 'CMS',
    title: 'CMS',
    icon: 'chrome',
    class: '',
    groupTitle: false,
    showMenu: false,
    submenu: []
  },
  // {
  //     path: 'CMS',
  //     title: 'CMS',
  //     icon: 'language',
  //     class: 'menu-toggle',
  //     groupTitle: false,
  //     submenu: [
  //         {
  //             path: '',
  //             title: 'Home',
  //             icon: 'home',
  //             class: 'menu-toggle',
  //             groupTitle: false,
  //             submenu: [
  //                 {
  //                     path: 'CMS/Webhome/headerimg',
  //                     title: 'Header Image',
  //                     icon: 'trello',
  //                     class: '',
  //                     groupTitle: false,
  //                     submenu: []
  //                 },
  //                 {
  //                     path: 'Webhome/Whatwedo',
  //                     title: 'What we do ',
  //                     icon: 'trello',
  //                     class: '',
  //                     groupTitle: false,
  //                     submenu: []
  //                 },
  //
  //             ]},
  //     ]
  // },
  {
    path: '/home/tasks',
    title: 'Tasks',
    icon: 'check-circle',
    class: '',
    groupTitle: false,
    showMenu: true,
    submenu: []
  },


  {
    path: '#',
    title: 'Masters',
    icon: 'command',
    class: 'menu-toggle',
    groupTitle: false,
    showMenu: false,
    submenu: [
      {
        path: '/home/allcentres',
        title: 'Centres',
        icon: 'umbrella',
        class: '',
        groupTitle: false,
        showMenu: false,
        submenu: []
      },
      {
        path: '/home/users',
        title: 'Users',
        icon: 'users',
        class: '',
        groupTitle: false,
        showMenu: false,
        submenu: []
      },
      {
        path: '/home/hospitals',
        title: 'Hospitals',
        icon: 'activity',
        class: '',
        groupTitle: false,
        showMenu: true,
        submenu: []
      },


      {
        path: 'types',
        title: 'Types',
        icon: 'sidebar',
        class: 'ml-menu',
        groupTitle: false,
        showMenu: false,
        submenu: []
      },
      {
        path: 'glossary',
        title: 'Glossary',
        icon: 'sidebar',
        class: 'ml-menu',
        groupTitle: false,
        showMenu: false,
        submenu: []
      },
      {
        path: '/home/Donor',
        title: 'Donor',
        icon: 'gift',
        class: '',
        groupTitle: false,
        showMenu: false,
        submenu: []
      },
      {
        path: '/home/Events',
        title: 'Events',
        icon: 'sidebar',
        class: '',
        groupTitle: false,
        showMenu: false,
        submenu: []
      },
      {
        path: 'inventory/contact_tbl',
        title: 'Contacts',
        icon: 'sidebar',
        class: 'ml-menu',
        groupTitle: false,
        showMenu: false,
        submenu: []
      },
      {
        path: '/home/activitylog',
        title: 'Audit Logs',
        icon: 'sidebar',
        class: '',
        groupTitle: false,
        showMenu: false,
        submenu: []
      },


    ]
  },


  // {
  //   path: 'contacts',
  //   title: 'MENUITEMS.CONTACTS.TEXT',
  //   icon: 'users',
  //   class: '',
  //   groupTitle: false,
  //   submenu: []
  // },
  // {
  //   path: '',
  //   title: 'MENUITEMS.EMAIL.TEXT',
  //   icon: 'mail',
  //   class: 'menu-toggle',
  //   groupTitle: false,
  //   submenu: [
  //     {
  //       path: '/email/inbox',
  //       title: 'MENUITEMS.EMAIL.LIST.INBOX',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/email/compose',
  //       title: 'MENUITEMS.EMAIL.LIST.COMPOSE',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/email/read-mail',
  //       title: 'MENUITEMS.EMAIL.LIST.READ',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       submenu: []
  //     }
  //   ]
  // },
  //
  // {
  //   path: '',
  //   title: 'MENUITEMS.MORE-APPS.TEXT',
  //   icon: 'command',
  //   class: 'menu-toggle',
  //   groupTitle: false,
  //   submenu: [
  //     {
  //       path: '/apps/chat',
  //       title: 'MENUITEMS.MORE-APPS.LIST.CHAT',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/apps/dragdrop',
  //       title: 'MENUITEMS.MORE-APPS.LIST.DRAG-DROP',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/apps/contact-grid',
  //       title: 'MENUITEMS.MORE-APPS.LIST.CONTACT-GRID',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/apps/support',
  //       title: 'MENUITEMS.MORE-APPS.LIST.SUPPORT',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       submenu: []
  //     }
  //   ]
  // },
  // {
  //   path: '',
  //   title: 'MENUITEMS.COMPONENTS.TEXT',
  //   icon: '',
  //   class: 'header',
  //   groupTitle: true,
  //   submenu: []
  // },
  // {
  //   path: '',
  //   title: 'MENUITEMS.WIDGETS.TEXT',
  //   icon: 'briefcase',
  //   class: 'menu-toggle',
  //   groupTitle: false,
  //   submenu: [
  //     {
  //       path: '/widget/chart-widget',
  //       title: 'MENUITEMS.WIDGETS.LIST.CHART-WIDGET',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/widget/data-widget',
  //       title: 'MENUITEMS.WIDGETS.LIST.DATA-WIDGET',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       submenu: []
  //     }
  //   ]
  // },
  // {
  //   path: '',
  //   title: 'MENUITEMS.FORMS.TEXT',
  //   icon: 'layout',
  //   class: 'menu-toggle',
  //   groupTitle: false,
  //   submenu: [
  //     {
  //       path: '/forms/form-controls',
  //       title: 'MENUITEMS.FORMS.LIST.CONTROLS',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/forms/advance-controls',
  //       title: 'MENUITEMS.FORMS.LIST.ADVANCE',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/forms/form-example',
  //       title: 'MENUITEMS.FORMS.LIST.EXAMPLE',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/forms/form-validation',
  //       title: 'MENUITEMS.FORMS.LIST.VALIDATION',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/forms/wizard',
  //       title: 'MENUITEMS.FORMS.LIST.WIZARD',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/forms/editors',
  //       title: 'MENUITEMS.FORMS.LIST.EDITORS',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       submenu: []
  //     }
  //   ]
  // },
  // {
  //   path: '',
  //   title: 'MENUITEMS.TABLES.TEXT',
  //   icon: 'grid',
  //   class: 'menu-toggle',
  //   groupTitle: false,
  //   submenu: [
  //     {
  //       path: '/tables/basic-tables',
  //       title: 'MENUITEMS.TABLES.LIST.BASIC',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/tables/material-tables',
  //       title: 'MENUITEMS.TABLES.LIST.MATERIAL',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/tables/ngx-datatable',
  //       title: 'MENUITEMS.TABLES.LIST.NGX-DATATABLE',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       submenu: []
  //     }
  //   ]
  // },
  // {
  //   path: '',
  //   title: 'Charts',
  //   icon: 'pie-chart',
  //   class: 'menu-toggle',
  //   groupTitle: false,
  //   submenu: [
  //     {
  //       path: '/charts/echart',
  //       title: 'Echart',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/charts/apex',
  //       title: 'Apex',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/charts/chartjs',
  //       title: 'ChartJS',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/charts/ngx-charts',
  //       title: 'Ngx-Charts',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/charts/gauge',
  //       title: 'Gauge',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       submenu: []
  //     }
  //   ]
  // },
  // {
  //   path: '',
  //   title: 'User Interface (UI)',
  //   icon: 'copy',
  //   class: 'menu-toggle',
  //   groupTitle: false,
  //   submenu: [
  //     {
  //       path: '/ui/alerts',
  //       title: 'Alerts',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/ui/badges',
  //       title: 'Badges',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/ui/chips',
  //       title: 'Chips',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/ui/modal',
  //       title: 'Modal',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/ui/buttons',
  //       title: 'Buttons',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/ui/expansion-panel',
  //       title: 'Expansion Panel',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/ui/bottom-sheet',
  //       title: 'Bottom Sheet',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/ui/dialogs',
  //       title: 'Dialogs',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/ui/cards',
  //       title: 'Cards',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/ui/labels',
  //       title: 'Labels',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/ui/list-group',
  //       title: 'List Group',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/ui/snackbar',
  //       title: 'Snackbar',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/ui/preloaders',
  //       title: 'Preloaders',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/ui/progressbars',
  //       title: 'Progress Bars',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/ui/tabs',
  //       title: 'Tabs',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/ui/typography',
  //       title: 'Typography',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/ui/helper-classes',
  //       title: 'Helper Classes',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       submenu: []
  //     }
  //   ]
  // },
  // {
  //   path: '',
  //   title: 'Icons',
  //   icon: 'feather',
  //   class: 'menu-toggle',
  //   groupTitle: false,
  //   submenu: [
  //     {
  //       path: '/icons/material',
  //       title: 'Material Icons',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/icons/font-awesome',
  //       title: 'Font Awesome',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/icons/simple-line',
  //       title: 'Simple Line Icons',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/icons/themify',
  //       title: 'Themify Icons',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       submenu: []
  //     }
  //   ]
  // },
  // {
  //   path: '',
  //   title: 'Maps',
  //   icon: 'map-pin',
  //   class: 'menu-toggle',
  //   groupTitle: false,
  //   submenu: [
  //     {
  //       path: '/maps/google',
  //       title: 'Google Map',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       submenu: []
  //     }
  //   ]
  // },
  // {
  //   path: '',
  //   title: 'EXTRA',
  //   icon: '',
  //   class: 'header',
  //   groupTitle: true,
  //   submenu: []
  // },
  // {
  //   path: '',
  //   title: 'Timeline',
  //   icon: 'git-pull-request',
  //   class: 'menu-toggle',
  //   groupTitle: false,
  //   submenu: [
  //     {
  //       path: '/timeline/timeline1',
  //       title: 'Timeline 1',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/timeline/timeline2',
  //       title: 'Timeline 2',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       submenu: []
  //     }
  //   ]
  // },
  // {
  //   path: '',
  //   title: 'Medias',
  //   icon: 'image',
  //   class: 'menu-toggle',
  //   groupTitle: false,
  //   submenu: [
  //     {
  //       path: '/media/gallery',
  //       title: 'Image Gallery',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       submenu: []
  //     }
  //   ]
  // },
  // {
  //   path: '',
  //   title: 'Authentication',
  //   icon: 'user-check',
  //   class: 'menu-toggle',
  //   groupTitle: false,
  //   submenu: [
  //     {
  //       path: '/authentication/signin',
  //       title: 'Sign In',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/authentication/signup',
  //       title: 'Sign Up',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/authentication/forgot-password',
  //       title: 'Forgot Password',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/authentication/locked',
  //       title: 'Locked',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/authentication/page404',
  //       title: '404 - Not Found',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/authentication/page500',
  //       title: '500 - Server Error',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       submenu: []
  //     }
  //   ]
  // },
  // {
  //   path: '',
  //   title: 'Extra Pages',
  //   icon: 'anchor',
  //   class: 'menu-toggle',
  //   groupTitle: false,
  //   submenu: [
  //     {
  //       path: '/extra-pages/profile',
  //       title: 'Profile',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/extra-pages/pricing',
  //       title: 'Pricing',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/extra-pages/invoice',
  //       title: 'Invoice',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/extra-pages/faqs',
  //       title: 'Faqs',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/extra-pages/blank',
  //       title: 'Blank Page',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       submenu: []
  //     }
  //   ]
  // },
  // {
  //   path: '',
  //   title: 'Multi level Menu',
  //   icon: 'chevrons-down',
  //   class: 'menu-toggle',
  //   groupTitle: false,
  //   submenu: [
  //     {
  //       path: '/multilevel/first1',
  //       title: 'First',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/',
  //       title: 'Second',
  //       icon: '',
  //       class: 'ml-sub-menu',
  //       groupTitle: false,
  //       submenu: [
  //         {
  //           path: '/multilevel/secondlevel/second1',
  //           title: 'Second 1',
  //           icon: '',
  //           class: 'ml-menu2',
  //           groupTitle: false,
  //           submenu: []
  //         },
  //         {
  //           path: '/',
  //           title: 'Second 2',
  //           icon: '',
  //           class: 'ml-sub-menu2',
  //           groupTitle: false,
  //           submenu: [
  //             {
  //               path: '/multilevel/thirdlevel/third1',
  //               title: 'third 1',
  //               icon: '',
  //               class: 'ml-menu3',
  //               groupTitle: false,
  //               submenu: []
  //             }
  //           ]
  //         }
  //       ]
  //     },
  //     {
  //       path: '/multilevel/first3',
  //       title: 'Third',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       submenu: []
  //     }
  //   ]
  // }
];







