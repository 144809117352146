import {Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup} from "@angular/forms";
import {AngularFirestore} from "@angular/fire/compat/firestore";
import {MatDialog} from "@angular/material/dialog";
import {AddCarLogBookComponent} from "../add-car-log-book/add-car-log-book.component";
import {ViewCarLogBookComponent} from "../view-car-log-book/view-car-log-book.component";
import {MatSort} from "@angular/material/sort";
import {MatPaginator} from "@angular/material/paginator";
import {MatTableDataSource} from '@angular/material/table';
import {VehicleService} from "../vehicle.service";
import {LoadingService} from "../../../services/loading.service";


export interface TripData {
  drop_location: string;
  endTripImage: string;
  id: string;
  pick_up_location: string;
  purpose_of_trip: string;
  reason: string | null;
  remark: string;
  startTripImage: string;
  status: string;
  trip_ended_at?: {
    nanoseconds: number;
    seconds: number;
  };
  trip_started_at: {
    nanoseconds: number;
    seconds: number;
  };
  vehicle_info: {
    driverAddress: string;
    driverContactNo: string | null;
    driverName: string;
    email: string;
    licenseNumber: string;
    vehicleNumber: string;
    vehicleType: string;
  };
}

@Component({
  selector: 'app-car-log-book',
  templateUrl: './car-log-book.component.html',
  styleUrls: ['./car-log-book.component.scss']
})
export class CarLogBookComponent implements OnInit {
  displayedColumns: string[] = ['tripDate', 'tripFrom', 'tripTo', 'vehicleNo', 'driverName', 'startTripDate', 'endTripDate', 'tripType', 'totalKms', 'action'];
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();
  // @ViewChild(MatSort) sort!: MatSort
  mStartDate: Date | null = null; // Initialize as null
  mEndDate: Date | null = null;
  drivers_name = '';
  vehicles_no = ''
  searchText: any;
  drivers: any[] = []
  vehicles: any[] = [];
  driverName: any;
  selectedVehicle: any;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  constructor(
    private mFirestore: AngularFirestore,
    private loadingService: LoadingService,
    private matDialog: MatDialog,
    private readonly mVehicleService: VehicleService) {
    this.dataSource = new MatTableDataSource<any>()
  }

  ngOnInit(): void {
    this.mVehicleService.getDriver().subscribe(res => {
      this.drivers = res
      console.log(this.drivers)
    })
    this.mVehicleService.getVehicles().subscribe(res => {
      console.log(res)
      this.vehicles = res
    })
    this.loadData();
  }
  loadData(){
    this.loadingService.setLoading(true);
    this.mFirestore.collection("ALTrips").valueChanges().subscribe(res => {
      console.log(res);
      this.dataSource = new MatTableDataSource<any>(res)
      this.dataSource.paginator = this.paginator
      this.dataSource.sort = this.sort;
      // Apply filters based on search criteria
      this.loadingService.setLoading(false);
    });
  }
  clearAll(){
    this.mStartDate = null;
    this.mEndDate = null
    this.drivers_name = ''
    this.vehicles_no = ''
    this.loadData();
  }

  openAddCarLog() {
    this.matDialog.open(AddCarLogBookComponent, {
      maxHeight: '100vh',
      maxWidth: '90vw'
    })
  }

  openView(element: TripData) {
    this.matDialog.open(ViewCarLogBookComponent, {
      data: element,
      maxHeight: '600px',
      maxWidth: '900vw',
    })
  }

  displayDate(dateObject: { nanoseconds: number; seconds: number } | null): string {
    if (!dateObject) {
      return 'N/A'; // or any default value for null dates
    }
    const date = new Date(dateObject.seconds * 1000 + dateObject.nanoseconds / 1e6);
    const day = ('0' + date.getDate()).slice(-2);
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const year = date.getFullYear();
    const hours = ('0' + date.getHours()).slice(-2);
    const minutes = ('0' + date.getMinutes()).slice(-2);
    const ampm = date.getHours() >= 12 ? 'PM' : 'AM';
    return `${day}/${month}/${year} ${hours}:${minutes} ${ampm}`;
  }
  displayDate2(dateObject: { nanoseconds: number; seconds: number }): string {
    const date = new Date(dateObject.seconds * 1000 + dateObject.nanoseconds / 1e6);
    const day = ('0' + date.getDate()).slice(-2);
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const year = date.getFullYear().toString().slice(-2);

    return `${day}/${month}/${year}`;
  }

  edit(element: any) {
    this.matDialog.open(AddCarLogBookComponent, {
      maxHeight: '100vh',
      maxWidth: '90vw',
      data: element
    })
  }

  deleteLog(element: any) {debugger
      this.mVehicleService.deleteVehicle(element.id).then(res => {
        // alert("data deleted successfully")
      })
  }

  calculateTotalKms(element: any): number {
    const pickUpMeterReading = parseFloat(element.pick_up_meter_reading);
    const dropMeterReading = parseFloat(element.drop_meter_reading);

    if (pickUpMeterReading == null || dropMeterReading == null) {
      // Handle missing or invalid data, e.g., return 0 or show an error
      return 0;
    } else if (isNaN(pickUpMeterReading) || isNaN(dropMeterReading)) {
      // Handle missing or invalid data, e.g., return 0 or show an error
      return 0;
    }
    const totalKms = Math.max(0, dropMeterReading - pickUpMeterReading);
    return totalKms;
  }

  searchData() {
    this.mFirestore.collection("ALTrips").valueChanges().subscribe((res: any[]) => {
      // Filter the data based on the provided search criteria
      const filteredData = res.filter((item: any) => {
        // Convert Firestore Timestamp to Date
        const tripDate = new Date(item.trip_started_at.seconds * 1000 + item.trip_started_at.nanoseconds / 1e6);
        // Define individual conditions
        const isDateInRange = this.mStartDate && this.mEndDate &&
          (tripDate >= this.mStartDate && tripDate <= this.mEndDate);

        const isVehicleMatch = this.selectedVehicle &&
          item.vehicle_info.vehicleNumber === this.selectedVehicle;

        const isDriverMatch = this.driverName &&
          item.vehicle_info.driverName === this.driverName;

        // Apply different filter combinations based on selected criteria
        if (this.mStartDate && this.mEndDate && this.selectedVehicle && this.driverName) {
          // All filters: Date range, vehicle, and driver
          return isDateInRange && isVehicleMatch && isDriverMatch;
        } else if (this.mStartDate && this.mEndDate && this.selectedVehicle) {
          // Date range and vehicle only
          return isDateInRange && isVehicleMatch;
        } else if (this.mStartDate && this.mEndDate && this.driverName) {
          // Date range and driver only
          return isDateInRange && isDriverMatch;
        } else if (this.selectedVehicle && this.driverName) {
          // Vehicle and driver only
          return isVehicleMatch && isDriverMatch;
        } else {
          // Default to OR logic if no specific combination is required
          return isDateInRange || isVehicleMatch || isDriverMatch;
        }
      });

      // Sort the filtered data by trip_started_at in descending order
      this.dataSource.data = filteredData.sort((a: any, b: any) => {
        const dateA = new Date(a.trip_started_at.seconds * 1000 + a.trip_started_at.nanoseconds / 1e6);
        const dateB = new Date(b.trip_started_at.seconds * 1000 + b.trip_started_at.nanoseconds / 1e6);

        // Sort in descending order
        return dateB.getTime() - dateA.getTime();
      });
      console.log("Filtered and sorted data:", this.dataSource.data);
    });
  }


  onDriverSelect($event: any) {
    this.driverName = $event.value
  }

  OnVehicleSelect($event: any) {
    this.selectedVehicle = $event.value

  }

  applyFilter($event: KeyboardEvent) {
    const filterValue = ($event.target as HTMLInputElement).value;
    // @ts-ignore
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
}
