import { Component, OnInit } from '@angular/core';
import {Centre} from "../../../../interfaces/Centres";
import {CentreService} from "../../../../services/centre.service";
import {Observable, Subject} from "rxjs";
import {AngularFirestore} from "@angular/fire/compat/firestore";
import {takeUntil} from "rxjs/internal/operators/takeUntil";

@Component({
  selector: 'app-education-mis-reposrt',
  templateUrl: './education-mis-reposrt.component.html',
  styleUrls: ['./education-mis-reposrt.component.scss']
})
export class EducationMisReposrtComponent implements OnInit {
  mCentres: Centre[] = [];
  centres$: Observable<Centre[]>;
  allCentres: any[] = [];
  headers: string[] = []; // Initialize headers dynamically
  startDate: Date | any;
  endDate: Date | any;
  selectedCentre = 'All'
  mTotalDischarge: number = 0;
  totalSessionCountBySession =0;
  educationData: any;
  highlights: any = '';
  mId: string | undefined
  sessionCounts: { [key: string]: { [key: string]: number } } = {}; // Store counts per centreCode and sessionType
  sessionData: { type: string; counts: number[]; total: number }[] = [];
  allCentreSessions: any[] = [];
  totalSessionCount :any;
  totalTssVolunteerCount=0;
  combinedAllTotals:any;
  centerAverages: number[] = []; // Initialize to hold averages
  childAverages: number[] = []; // Initialize to hold averages

  allRapportBuildingSessionCount: any[] = [];
  allRapportBuildingSessionCountTotal = 0 ;

  allBaselineAssessmentSessionCount : any[] = [];
  allBaselineAssessmentSessionCountTotal = 0 ;

  allIntermediateAssessmentSessionCount :any[] = [];
  allIntermediateAssessmentSessionCountTotal = 0;

  allDischargeTestSessionCount :any [] = [];
  allDischargeTestSessionCountTotal = 0;

  allIndividualSessionsByALTeacherCount :any [] = [];
  allIndividualSessionsByALTeacherTotal  = 0;

  allGroupSessionsCount :any [] = [];
  allGroupSessionsTotal = 0;

  allIndividualSessionsdoneVolunteersCount : any [] = [];
  allIndividualSessionsdoneVolunteersTotal = 0;
  singleCount = 0
  childrenCount = 0
  mTeacherSessionsGroup = 0;
  mVolunteerSessionsGroup = 0;
  isLoading: boolean = false;
  private unsubscribe$ = new Subject<void>();
  constructor( private centreService: CentreService,
               private mFirestore: AngularFirestore,) {
    this.centres$ = this.centreService.getAllCentres();
    this.centres$.subscribe(res => {
      this.mCentres = res
      this.allCentres = res
        .filter(c => c.centreCode !== 'ALBandra')
        .sort((a, b) => a.centreName.localeCompare(b.centreName));
      // Set headers as an array of centre names
      this.headers = this.allCentres.map(c => c.centreName);
    })
  }
  ngOnInit(): void {
  }

  rapportBuildingSessionCount(data: any[]) {
    // Create an object to store center-wise session counts
    const centerSessionCount: { [centreCode: string]: number } = {};

    // Filter the data to only count 'Rapport Building' sessions with 'Present' status
    const rapportBuildingSessions = data.filter((session: any) =>
      session.sessionType === 'Rapport Building' && session.present === 'Present'
    );

    // Loop through filtered sessions and count by centreCode
    rapportBuildingSessions.forEach((session: any) => {
      const centreCode = session.centreCode;  // Get the centreCode from the session

      // Initialize count for this center if it doesn't exist
      if (!centerSessionCount[centreCode]) {
        centerSessionCount[centreCode] = 0;
      }

      // Increment the count for the respective centreCode
      centerSessionCount[centreCode]++;
    });

    // Update the allRapportBuildingSessionCount array with session counts
    this.allRapportBuildingSessionCount = this.mCentres.map(centre => {
      return {
        centreName: centre.centreName,
        sessionCountBySession: centerSessionCount[centre.centreCode] || 0
      };
    });
    // Calculate total session count across all centres
    this.allRapportBuildingSessionCountTotal = rapportBuildingSessions.length;
  }
  baselineAssessmentSessionCount(data:any[]) {
        // Create an object to store center-wise session counts for Baseline Assessment
        const centerSessionCount: { [centreCode: string]: number } = {};

        // Filter the data to only count 'Baseline Assessment' sessions with 'Present' status
        const baselineAssessmentSessions = data.filter((session: any) =>
          session.sessionType === 'Baseline Assessment' && session.present === 'Present'
        );

        // Loop through filtered sessions and count by centreCode
        baselineAssessmentSessions.forEach((session: any) => {
          const centreCode = session.centreCode;  // Get the centreCode from the session

          // Initialize count for this center if it doesn't exist
          if (!centerSessionCount[centreCode]) {
            centerSessionCount[centreCode] = 0;
          }
          // Increment the count for the respective centreCode
          centerSessionCount[centreCode]++;
        });
        // Update the allCentreSessions array with session counts for Baseline Assessment
        this.allBaselineAssessmentSessionCount = this.mCentres.map(centre => {
          return {
            centreName: centre.centreName,
            sessionCountBySession: centerSessionCount[centre.centreCode] || 0
          };
        });
        // Calculate total session count across all centres for Baseline Assessment
        this.allBaselineAssessmentSessionCountTotal = baselineAssessmentSessions.length;
  }
  intermediateAssessmentSessionCount(data:any[]) {
        // Create an object to store center-wise session counts for Intermediate Assessment
        const centerSessionCount: { [centreCode: string]: number } = {};

        // Filter the data to only count 'Intermediate Assessment' sessions with 'Present' status
        const intermediateAssessmentSessions = data.filter((session: any) =>
          session.sessionType === 'Intermediate Assessment' && session.present === 'Present'
        );

        // Loop through filtered sessions and count by centreCode
        intermediateAssessmentSessions.forEach((session: any) => {
          const centreCode = session.centreCode;  // Get the centreCode from the session

          // Initialize count for this center if it doesn't exist
          if (!centerSessionCount[centreCode]) {
            centerSessionCount[centreCode] = 0;
          }
          // Increment the count for the respective centreCode
          centerSessionCount[centreCode]++;
        });
        // Update the allCentreSessions array with session counts for Intermediate Assessment
        this.allIntermediateAssessmentSessionCount = this.mCentres.map(centre => {
          return {
            centreName: centre.centreName,
            sessionCountBySession: centerSessionCount[centre.centreCode] || 0
          };
        });

        // Calculate total session count across all centres for Intermediate Assessment
        this.allIntermediateAssessmentSessionCountTotal = intermediateAssessmentSessions.length;
  }
  individualSessionsDoneVolunteers(data:any[]) {
      this.singleCount = 0;
      this.childrenCount = 0;
      this.mVolunteerSessionsGroup = 0;
      this.mTeacherSessionsGroup = 0;

      // Initialize an object to store center-wise counts
      const centerSessionCounts: { [centreCode: string]: { singleCount: number; childrenCount: number; volunteerCount: number; teacherCount: number } } = {};
      this.educationData = data;

      // Iterate through each session to calculate center-wise counts
      this.educationData.forEach((session: any, index: number) => {
        const centreCode = session.centres[0].centreCode; // Access center code within the 'centres' array

        // Initialize the center entry if it doesn't exist
        if (!centerSessionCounts[centreCode]) {
          centerSessionCounts[centreCode] = { singleCount: 0, childrenCount: 0, volunteerCount: 0, teacherCount: 0 };
        }
        // Check the countType and update counts accordingly for the specific center
        if (session.countType === 'bysession') {
          centerSessionCounts[centreCode].singleCount++;} else if (session.countType === 'bychildren') {
          centerSessionCounts[centreCode].childrenCount += session.children.length;
        }

        // Update volunteer and teacher session counts based on volunteerName
        if (session.volunteerName) {
          centerSessionCounts[centreCode].volunteerCount++;
        } else {
          centerSessionCounts[centreCode].teacherCount++;
        }
      });

      // Ensure all centers are included and set defaults if no data for a center
      this.mCentres.forEach((centre: any) => {
        if (!centerSessionCounts[centre.centreCode]) {
          centerSessionCounts[centre.centreCode] = { singleCount: 0, childrenCount: 0, volunteerCount: 0, teacherCount: 0 }; // Default to 0 if no data exists
        }
      });

      // Convert centerSessionCounts to an array and sort by centreCode
      this.allIndividualSessionsdoneVolunteersCount = Object.entries(centerSessionCounts)
        .map(([centreCode, counts]) => ({
          centreCode,
          singleCount: counts.singleCount || 0,       // Default to 0 if singleCount is undefined or 0
          childrenCount: counts.childrenCount || 0,     // Default to 0 if childrenCount is undefined or 0
          volunteerCount: counts.volunteerCount || 0,   // Default to 0 if volunteerCount is undefined or 0
          teacherCount: counts.teacherCount || 0        // Default to 0 if teacherCount is undefined or 0
        }))
        .sort((a, b) => a.centreCode.localeCompare(b.centreCode)); // Sort by centreCode
       // Aggregate totals across all centers
      this.singleCount = Object.values(centerSessionCounts).reduce((sum, center) => sum + center.singleCount, 0);
      this.childrenCount = Object.values(centerSessionCounts).reduce((sum, center) => sum + center.childrenCount, 0);
      this.mVolunteerSessionsGroup = Object.values(centerSessionCounts).reduce((sum, center) => sum + center.volunteerCount, 0);
      this.mTeacherSessionsGroup = Object.values(centerSessionCounts).reduce((sum, center) => sum + center.teacherCount, 0);
      this.allIndividualSessionsdoneVolunteersTotal += this.childrenCount;
  }
  dischargeTestSessionCount(data:any[]) {
        // Create an object to store center-wise session counts for Discharge Test
        const centerSessionCount: { [centreCode: string]: number } = {};
        // Filter the data to only count 'Discharge Test' sessions with 'Present' status
        const dischargeTestSessions = data.filter((session: any) =>
          session.sessionType === 'Discharge Assessment' && session.present === 'Present'
        );

        // Loop through filtered sessions and count by centreCode
        dischargeTestSessions.forEach((session: any) => {
          const centreCode = session.centreCode;  // Get the centreCode from the session

          // Initialize count for this center if it doesn't exist
          if (!centerSessionCount[centreCode]) {
            centerSessionCount[centreCode] = 0;
          }
          // Increment the count for the respective centreCode
          centerSessionCount[centreCode]++;
        });
        this.allDischargeTestSessionCount = this.mCentres.map(centre => {
          return {
            centreName: centre.centreName,
            sessionCountBySession: centerSessionCount[centre.centreCode] || 0
          };
        });
        // Calculate total session count across all centres for Discharge Test
        this.allDischargeTestSessionCountTotal = dischargeTestSessions.length;
  }
  individualSessionsByALTeacherSessionCount(data:any[]) {
        const centerSessionCount: { [centreCode: string]: number } = {};
        const individualSessionsByALTeacher = data.filter((session: any) =>
          session.conductedBy === 'By Teacher' && session.present === 'Present' // Ensuring session is by teacher and present
        );
    const regularStudySessions = individualSessionsByALTeacher.filter((session: any) => session.sessionType === "Regular Study");
    regularStudySessions.forEach((session: any) => {
      const centreCode = session.centreCode; // Get the centreCode from the session
      if (!centerSessionCount[centreCode]) {
        centerSessionCount[centreCode] = 0;
      }
      centerSessionCount[centreCode]++;
    });
    this.allIndividualSessionsByALTeacherCount = this.mCentres.map(centre => {
      return {
        centreName: centre.centreName,
        sessionCountBySession: centerSessionCount[centre.centreCode] || 0
      };
    });

// Total count of sessions with "Regular Study"
    this.allIndividualSessionsByALTeacherTotal = regularStudySessions.length;

  }
  groupSessionsSessionCount(data:any[]) {
    // Initialize an object to store the count of sessions for each centreCode, excluding sessions with TSS
    let centreCodeWiseCount: { [key: string]: number } = {};

    // Loop through the result to count the data by centreCode, excluding TSS sessions
    data.forEach((session: any) => {
      const centreCode = session.centres[0]?.centreCode; // Assuming the first centre in the array is the main centre
      const isTSSSession = session.volunteerName === "Tss" || session.topicsCovered === "Tss";

      // Only count the session if it's not a TSS session
      if (centreCode && !isTSSSession) {
        if (!centreCodeWiseCount[centreCode]) {
          centreCodeWiseCount[centreCode] = 0;
        }
        centreCodeWiseCount[centreCode]++; // Increment the count for this centreCode
      }
    });

    // Ensure every center has an entry, defaulting to 0 if no session data is available for the centreCode
    this.allGroupSessionsCount = this.mCentres.map((centre: any) => {
      const centreCode = centre.centreCode;
      const sessionCount = centreCodeWiseCount[centreCode] || 0;
      return {
        centreCode,
        sessionCountBySession: sessionCount
      };
    });

    // Calculate the total session count
    this.allGroupSessionsTotal = Object.values(centreCodeWiseCount).reduce((total, count) => total + count, 0);
  }
  calculateGrandTotal(): number[] {
    const grandTotals: number[] = [];

    // Add session count totals for each session type (A+B+C+D+E+F+G)
    const sessionCounts = [
      this.allRapportBuildingSessionCountTotal,
      this.allBaselineAssessmentSessionCountTotal,
      this.allIntermediateAssessmentSessionCountTotal,
      this.allDischargeTestSessionCountTotal,
      this.allIndividualSessionsByALTeacherTotal,
      this.allGroupSessionsTotal,
      this.allIndividualSessionsdoneVolunteersTotal
    ];

    // Calculate the grand total by summing the session totals
    const grandTotal = sessionCounts.reduce((acc, curr) => acc + curr, 0);

    // Add the grand total to the array for the last column
    grandTotals.push(grandTotal);

    return grandTotals;
  }
  calculateCenterWiseTotalSessionSum(): number[] {
    // Arrays of session counts for each session type (A, B, C, etc.)
    const allSessionArrays = [
      this.allRapportBuildingSessionCount,  // Array 1
      this.allBaselineAssessmentSessionCount, // Array 2
      this.allIntermediateAssessmentSessionCount, // Array 3
      this.allDischargeTestSessionCount, // Array 4
      this.allIndividualSessionsByALTeacherCount, // Array 5
      this.allGroupSessionsCount, // Array 6
      this.allIndividualSessionsdoneVolunteersCount // Array 7
    ];

    // Initialize an array to hold the total session counts for each center (same length as mCentres)
    const centerWiseTotals: number[] = new Array(this.mCentres.length).fill(0);  // Ensure the length matches mCentres

    // Iterate through each session array
    allSessionArrays.forEach((sessionArray, arrayIndex) => {

      // Loop through each session in the current session array
      sessionArray.forEach((session, index) => {
        // Check if session count exists for the current session
        const sessionCount = session.sessionCountBySession || 0;
        const childrenCount = session.childrenCount || 0;
        // Add session count to the respective center total
        centerWiseTotals[index] += sessionCount + childrenCount;
        // Log session count addition for debugging
       });
    });
    // Sum up all the center-wise totals and store it in a variable
    const totalCount = centerWiseTotals.reduce((acc, total) => acc + total, 0);
    this.centerAverages = centerWiseTotals
    return centerWiseTotals;
  }
  getGroupUniqChild() {
    if (this.startDate != null && this.endDate != null) {
      // Query the educationGroupTest collection with date range filtering
      this.mFirestore.collection('educationGroupTest', ref =>
        ref.where('sessionDate', '>=', this.startDate)
          .where('sessionDate', '<=', this.endDate)
      ).valueChanges().pipe(
        takeUntil(this.unsubscribe$)
      ).subscribe((res: any[]) => {
        // Object to hold unique children by center code
        const centerWiseChildCounts: { [centreCode: string]: Set<string> } = {};

        // Loop through the result set
        res.forEach(session => {
          if (session.children && Array.isArray(session.children)) {
            session.children.forEach((child: any) => {
              if (child.childCode && child.centreCode) {
                // Ensure the centerCode exists for the child
                if (!centerWiseChildCounts[child.centreCode]) {
                  centerWiseChildCounts[child.centreCode] = new Set<string>();
                }
                // Add the childCode to the respective center's set
                centerWiseChildCounts[child.centreCode].add(child.childCode);
              }
            });
          }
        });

        // Log the unique child count for each center
        Object.keys(centerWiseChildCounts).forEach(centreCode => {
          const uniqueChildrenCount = centerWiseChildCounts[centreCode].size;
        });

        // Optionally, return or store the center-wise counts if needed
        return centerWiseChildCounts;
      });
    } else {
    }
  }
  getIndividualUniqChild() {
    if (this.startDate != null && this.endDate != null) {
      // Query the educationTest collection with date range filtering
      this.mFirestore.collection('educationTest', ref =>
        ref.where('sessionDate', '>=', this.startDate)
          .where('sessionDate', '<=', this.endDate)
          .where('present', '==', 'Present')
      ).valueChanges().pipe(
        takeUntil(this.unsubscribe$)
      ).subscribe((res: any[]) => {
        // Object to hold unique children by center code
        const centerWiseChildCounts: { [centreCode: string]: Set<string> } = {};

        // Loop through each session in the response
        res.forEach(session => {
          if (session.childCode && session.centreCode) {
            // Ensure the centerCode exists for the child
            if (!centerWiseChildCounts[session.centreCode]) {
              centerWiseChildCounts[session.centreCode] = new Set<string>();
            }
            // Add the childCode to the respective center's set
            centerWiseChildCounts[session.centreCode].add(session.childCode);
          }
        });

        // Log the unique child count for each center
        Object.keys(centerWiseChildCounts).forEach(centreCode => {
          const uniqueChildrenCount = centerWiseChildCounts[centreCode].size;
          console.log(`Centre: ${centreCode}, Unique Children: ${uniqueChildrenCount}`);
          this.getChildAvg(centreCode, uniqueChildrenCount);
        });

        // Optionally, return or store the center-wise counts if needed
        return centerWiseChildCounts;
      });
    } else {
    }
  }
  customRound(value: number): number {
    if (value % 1 >= 0.50 && value % 1 < 0.5) {
      // Round up to 6 if the decimal part is between 0.1 and 0.5
      return Math.ceil(value);
    }
    // Default rounding
    return Math.round(value);
  }
  getChildAvg(centreCode: string, uniqueChild: number): number {
    const centreIndex = this.mCentres.findIndex(centre => centre.centreCode === centreCode);
    const totalForCentre = this.calculateCenterWiseTotalSessionSum()[centreIndex] || 0
    const average = (uniqueChild > 0 && totalForCentre > 0) ? totalForCentre / uniqueChild : 0;
    this.childAverages[centreIndex] = average;
    this.childAverages = this.mCentres
      .map((centre, index) => ({ centreCode: centre.centreCode, avg: this.childAverages[index] })) // Map centre and avg
      .sort((a, b) => {
        const indexA = this.mCentres.findIndex(centre => centre.centreCode === a.centreCode);
        const indexB = this.mCentres.findIndex(centre => centre.centreCode === b.centreCode);
        return indexA - indexB; // Sorting based on the index in mCentres array
      })
      .map(item => item.avg); // Extract the sorted averages
    this.childAverages = this.childAverages.map(avg => avg === 0 ? 0 : avg);
    return average;
  }
  get totalAverage(): number {
    // Sum up all values in childAverages
    const total = this.childAverages.reduce((sum, avg) => sum + (avg || 0), 0);
    return total;
  }

  Search(){
    if (this.startDate != null && this.endDate != null) {
      this.isLoading = true;
      // Query the educationTest collection without any sessionType or present filtering
      this.mFirestore.collection('educationTest', ref =>
        ref.where('sessionDate', '>=', this.startDate)
          .where('sessionDate', '<=', this.endDate)
      ).valueChanges().pipe(takeUntil(this.unsubscribe$)).subscribe((res: any) => {
        this.isLoading = false;
        this.rapportBuildingSessionCount(res);
        this.baselineAssessmentSessionCount(res)
        this.intermediateAssessmentSessionCount(res);
        this.dischargeTestSessionCount(res);
        this.individualSessionsByALTeacherSessionCount(res);
      })

      this.mFirestore.collection('educationGroupTest', ref =>
        ref.where('sessionDate', '>=', this.startDate)
          .where('sessionDate', '<=', this.endDate)
      ).valueChanges().pipe(
        takeUntil(this.unsubscribe$)
      ).subscribe((res: any[]) => {
        this.individualSessionsDoneVolunteers(res);
        this.groupSessionsSessionCount(res);
      })
    }
    this.calculateGrandTotal();
    this.calculateCenterWiseTotalSessionSum();
    this.getIndividualUniqChild();
    this.getGroupUniqChild();
  }

  Clear() {
    this.startDate = null;
    this.endDate = null;
    this.selectedCentre = '';
    this.sessionData = [];
    this.allCentreSessions = [];
    this.totalSessionCount = 0;
    this.totalTssVolunteerCount = 0;
    this.totalSessionCountBySession = 0;
    this.combinedAllTotals = []; // Optional: reset combined totals if needed
    this.centerAverages = []; // Optional: reset averages
  }

}
