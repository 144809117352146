ssdf
<div class="container">

  <div class=" pt-5 ">
    <mat-card>
      <mat-card-header>
        <!--            <mat-card-title-group>-->
        <div mat-card-avatar class="example-header-image"></div>
        <mat-card-title>{{receipt.donorName}}  </mat-card-title>
        <mat-card-subtitle> {{receipt.date.toDate()| date:'medium'}} </mat-card-subtitle>
        <!--              <img mat-card-sm-image src="https://material.angular.io/assets/img/examples/shiba2.jpg" >-->
        <!--            </mat-card-title-group>-->
      </mat-card-header>
      <hr>
      <mat-card-content>
        <table class="table table-striped">
          <thead>
          <tr>
            <th scope="col"></th>
            <th scope="col"></th>
          </tr>
          </thead>
          <tbody>
<!--          <tr><th scope="row">Receipt Number</th>-->
<!--            <td>{{receipt.receipt_id}}</td>-->
<!--          </tr>-->
          <tr><th scope="row">DONOR NAME</th>
            <td>{{receipt.donorName}}</td>
          </tr>
<!--          <tr><th >Payment Id</th><td>{{receipt.order_id}}</td></tr>-->
          <tr><th >Date</th><td>{{receipt.date.toDate()| date:'medium'}}</td></tr>
          <tr><th >Donation Type</th><td>{{receipt.donation_type||'GF'}}</td></tr>
          <tr><th >Amount</th><td>{{receipt.amount}}</td></tr>
          <tr><th >Email</th><td>{{receipt.email}}</td></tr>
          <tr><th >PAN</th><td>{{receipt.pan}}</td></tr>
          <tr><th >Number</th><td>{{receipt.donorMobile}}</td></tr>
          <tr><th >Address</th><td>{{receipt.donoraddress}}</td></tr>
          <tr><th >State</th><td>{{receipt.state}}</td></tr>
          <tr><th >Zipcode</th><td>{{receipt.zipcode}}</td></tr>
          <tr><th >Country</th><td>India</td></tr>
          <tr><th >Nationality</th><td>Indian</td></tr>
          <!--              <tr><th >Method</th><td>{{receipt.method}}</td></tr>-->


          </tbody>
        </table>
      </mat-card-content>
    </mat-card>



  </div>

  <!--      RESPONSE-->

</div>

