import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {AngularFirestore} from "@angular/fire/compat/firestore";
import {AdmissionLog, FullChild} from "../interfaces/FullChild";
import {child} from "@angular/fire/database";
import {Timestamp} from "firebase/firestore";
import {throwIfEmpty} from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class AdmissionLogService {

    constructor(private readonly mFirestore: AngularFirestore) {

    }


    CreateAdmissionLogs() {
        this.mFirestore.collection<FullChild>('ALChild', ref => ref.orderBy('childCode')).valueChanges().subscribe(res => {
            let children = res
            children.forEach((child) => {
                let log = this.getAdmissionLogsFromChild(child)
            })

        })
    }

    getAdmissionLogsFromChild(child: FullChild) {
        let logs: AdmissionLog[]
        child.admissionData.forEach((admission, i: number) => {
            if (i % 2 == 0) {
                let log: AdmissionLog = {
                    centreName: admission.centreName,
                    childCode: child.childCode,
                    currentUnit: child.selectUnit,
                    dateOfAdmission: (admission.dateOfAdmission as unknown as Timestamp)!.toDate(),
                    // dateOfDischarge: (admission.dateOfAdmission as unknown as Timestamp)?.toDate()||null,
                    dob: child.dob.toDate(),
                    gender: child.gender,
                    hospital: child.hospital,
                    isUnique: i == 0 || i == 1,
                    type: i % 2 ? 'Admission' : 'Discharge',
                    centreCode: admission.centreCode,
                    bloodGroup: child.bloodGroup,
                    diagnosis: child.diagnosisSelected,
                    state: child.state,
                }
                this.pushAdmissionLogToDB(log)
            } else if (i % 2 != 0) {
                let log: AdmissionLog = {
                    centreName: admission.centreName||child.centreName,
                    childCode: child.childCode ,
                    currentUnit: admission.currentUnit|| child.selectUnit,
                    dateOfDischarge: (admission.dateOfDischarge as unknown as Timestamp)!.toDate(),
                    // dateOfDischarge: (admission.dateOfAdmission as unknown as Timestamp)?.toDate()||null,
                    dob: child.dob.toDate(),
                    gender: child.gender,
                    hospital: child.hospital,
                    isUnique: i == 0 || i == 1,
                    type: i % 2 ? 'Admission' : 'Discharge',
                    centreCode: admission.centreCode||child.centreCode,
                    bloodGroup: child.bloodGroup,
                    diagnosis: child.diagnosisSelected,
                    state: child.state,
                }
                this.pushAdmissionLogToDB(log)

            }

        })


    }

    pushAdmissionLogToDB(log: AdmissionLog) {
        this.mFirestore.collection('AdmissionLogs').add(log).then(res=>{
          console.log('ADDED TO DB '+res.id)
        })

    }
    fetchAdmissionLogs() {
     return  this.mFirestore.collection('AdmissionLogs').get().toPromise()
    }
deleteAllAdmissionLogs() {
  this.mFirestore.collection('AdmissionLogs').get().toPromise().then(res=>{
    res?.forEach(it=>{
      this.mFirestore.collection('AdmissionLogs').doc(it.id).delete()
    })
  })

}

}
