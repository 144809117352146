<div class="container">
  <div>
    <BR>
    <mat-form-field appearance="outline">
      <mat-label>Select Centre</mat-label>
      <mat-select (selectionChange)="onCenterSelected($event)" >
<!--        <mat-option value="centreCode">centreName</mat-option>-->
        <ng-container *ngFor="let center of ($center|async );" >
        <mat-option  *ngIf="center.isArchive!=true" [value]="center">{{center.centreName}}</mat-option></ng-container>
      </mat-select>
    </mat-form-field>
    <!--    <button color="accent" mat-raised-button id="create-center"  (click)="AddUnit()"><mat-icon>add</mat-icon>&nbsp;Create center</button>&nbsp;-->

  </div>
  <br>
<!--  <div class="d-flex justify-content-end">-->
<!--<div class="mx-2">-->
    <div class="d-flex justify-content-end mr-2">
  <button mat-stroked-button color="primary" (click)="transfer()">Transfer</button>
    </div>
    <br>

<!--</div>-->
<!--</div>-->
<div class="card p-2">
  <div class="ngxTableHeader">
    <ul class="header-buttons-left ms-0 mb-0">
      <li class="dropdown">
                  <h2>Units</h2>
      </li>
      <li class="dropdown m-l-20">
        <label for="search-input"><i class="material-icons search-icon">search</i></label>
        <input placeholder="Search" type="text" class="browser-default search-field" id="search-input"
               (keyup)='applyFilter($event)' aria-label="Search box" #input>
      </li>
    </ul>
    <ul class="header-buttons mr-2">
            <li>
<!--              <button   mat-mini-fab color="primary" (click)="transfer()">-->
<!--                <mat-icon class="col-white" >add</mat-icon>-->
<!--              </button>-->
            </li>
      <!--        <li>-->
      <!--          <div  class="export-button m-l-10" matTooltip="XLSX" (click)="exportexcel()">-->
      <!--            <img src="assets/images/icons/xlsx.png" alt="" />-->
      <!--          </div>-->
      <!--        </li>-->
    </ul>
  </div>


  <div class="">
    <table mat-table [dataSource]="dataSource" matSort>

      <!-- ID Column -->
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>  Id  </th>
        <td mat-cell *matCellDef="let row" matTooltip="{{row.key}}"> {{row.key}} </td>
      </ng-container>
      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Child Name </th>
        <td mat-cell *matCellDef="let row"> {{row.name}} </td>
      </ng-container>
      <!-- Floor Column -->
      <ng-container matColumnDef="floorNo">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Floor </th>
        <td mat-cell *matCellDef="let row"> {{row.floorNo}} </td>
      </ng-container>

      <!-- Progress Column -->
      <ng-container matColumnDef="centreName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Center Name </th>
        <td mat-cell *matCellDef="let row"> {{row.centreName}}</td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="allotedTo">

          <th mat-header-cell *matHeaderCellDef mat-sort-header> Alloted to </th>
          <td mat-cell *matCellDef="let row"> {{row.allotedTo}} </td>

      </ng-container>

      <!-- Fruit Column -->
      <ng-container matColumnDef="status">

          <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
          <td mat-cell *matCellDef="let row"> {{row.status}}&nbsp;&nbsp;&nbsp; </td>

      </ng-container>

      <!-- Action Column -->
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Action</th>

        <td mat-cell *matCellDef="let row" >
<!--       <button  mat-stroked-button color="primary" (click)="setFloor(row)">Allot Floor</button>&nbsp;&nbsp;-->
<!--       <button *ngIf="row.allotedTo=='NA'" mat-raised-button color="primary" (click)="AssignAdHOC(row.key)">Allot Child</button>-->
        </td>
      </ng-container>


        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>

        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

      <!-- Row shown when there is no matching data. -->
      <tr class="mat-row" *matNoDataRow>
        <td style="width: 100%" class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
      </tr>

    </table>

    <mat-paginator [pageSizeOptions]="[25, 50, 100,1000]" aria-label="Select page of users"></mat-paginator>
  </div>
</div>
</div>
